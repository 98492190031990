.loader-wrapper {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0 auto;
}

.loader-item {
    font-size: 6px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    text-indent: -9999em;
    animation: load 1.1s infinite ease;
    transform: translate(-50%,-50%);
    @keyframes load {
        0%,
        100% {
            box-shadow: 0em -2.6em 0em 0em #030303, 1.8em -1.8em 0 0em rgba(3,3,3, 0.2), 2.5em 0em 0 0em rgba(3,3,3, 0.2), 1.75em 1.75em 0 0em rgba(3,3,3, 0.2), 0em 2.5em 0 0em rgba(3,3,3, 0.2), -1.8em 1.8em 0 0em rgba(3,3,3, 0.2), -2.6em 0em 0 0em rgba(3,3,3, 0.5), -1.8em -1.8em 0 0em rgba(3,3,3, 0.7);
        }
        12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(3,3,3, 0.7), 1.8em -1.8em 0 0em #030303, 2.5em 0em 0 0em rgba(3,3,3, 0.2), 1.75em 1.75em 0 0em rgba(3,3,3, 0.2), 0em 2.5em 0 0em rgba(3,3,3, 0.2), -1.8em 1.8em 0 0em rgba(3,3,3, 0.2), -2.6em 0em 0 0em rgba(3,3,3, 0.2), -1.8em -1.8em 0 0em rgba(3,3,3, 0.5);
        }
        25% {
            box-shadow: 0em -2.6em 0em 0em rgba(3,3,3, 0.5), 1.8em -1.8em 0 0em rgba(3,3,3, 0.7), 2.5em 0em 0 0em #030303, 1.75em 1.75em 0 0em rgba(3,3,3, 0.2), 0em 2.5em 0 0em rgba(3,3,3, 0.2), -1.8em 1.8em 0 0em rgba(3,3,3, 0.2), -2.6em 0em 0 0em rgba(3,3,3, 0.2), -1.8em -1.8em 0 0em rgba(3,3,3, 0.2);
        }
        37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(3,3,3, 0.2), 1.8em -1.8em 0 0em rgba(3,3,3, 0.5), 2.5em 0em 0 0em rgba(3,3,3, 0.7), 1.75em 1.75em 0 0em #030303, 0em 2.5em 0 0em rgba(3,3,3, 0.2), -1.8em 1.8em 0 0em rgba(3,3,3, 0.2), -2.6em 0em 0 0em rgba(3,3,3, 0.2), -1.8em -1.8em 0 0em rgba(3,3,3, 0.2);
        }
        50% {
            box-shadow: 0em -2.6em 0em 0em rgba(3,3,3, 0.2), 1.8em -1.8em 0 0em rgba(3,3,3, 0.2), 2.5em 0em 0 0em rgba(3,3,3, 0.5), 1.75em 1.75em 0 0em rgba(3,3,3, 0.7), 0em 2.5em 0 0em #030303, -1.8em 1.8em 0 0em rgba(3,3,3, 0.2), -2.6em 0em 0 0em rgba(3,3,3, 0.2), -1.8em -1.8em 0 0em rgba(3,3,3, 0.2);
        }
        62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(3,3,3, 0.2), 1.8em -1.8em 0 0em rgba(3,3,3, 0.2), 2.5em 0em 0 0em rgba(3,3,3, 0.2), 1.75em 1.75em 0 0em rgba(3,3,3, 0.5), 0em 2.5em 0 0em rgba(3,3,3, 0.7), -1.8em 1.8em 0 0em #030303, -2.6em 0em 0 0em rgba(3,3,3, 0.2), -1.8em -1.8em 0 0em rgba(3,3,3, 0.2);
        }
        75% {
            box-shadow: 0em -2.6em 0em 0em rgba(3,3,3, 0.2), 1.8em -1.8em 0 0em rgba(3,3,3, 0.2), 2.5em 0em 0 0em rgba(3,3,3, 0.2), 1.75em 1.75em 0 0em rgba(3,3,3, 0.2), 0em 2.5em 0 0em rgba(3,3,3, 0.5), -1.8em 1.8em 0 0em rgba(3,3,3, 0.7), -2.6em 0em 0 0em #030303, -1.8em -1.8em 0 0em rgba(3,3,3, 0.2);
        }
        87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(3,3,3, 0.2), 1.8em -1.8em 0 0em rgba(3,3,3, 0.2), 2.5em 0em 0 0em rgba(3,3,3, 0.2), 1.75em 1.75em 0 0em rgba(3,3,3, 0.2), 0em 2.5em 0 0em rgba(3,3,3, 0.2), -1.8em 1.8em 0 0em rgba(3,3,3, 0.5), -2.6em 0em 0 0em rgba(3,3,3, 0.7), -1.8em -1.8em 0 0em #030303;
        }
    }
}

.paginate {
  display: flex;
  margin: 20px 0;
  padding: 0;
  list-style: none;
  justify-content: center;
  li {
    list-style: none;
    display: inline-block;
    border-radius: 0;
    font-size: .78rem;
    margin-right: .0625rem;
    a {
      cursor: pointer;
      background: transparent;
      border: none;
      display: block;
      padding: .167rem .58rem;
      border-radius: 0;
      color: #555555;
      outline: none;
    }
    &.active {
      a {
        background: #667784;
        color: #fefefe;
      }
    }
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}

.container {
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  &.empty {
    padding-top: 20px;
    padding-bottom: 26px;
  }
}

.row {
  display: flex;
}

.col-md-3 {
  width: 25%;
  padding: 1em;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

@media screen and (min-width: 1801px) {
  .page-wrapper {
    box-shadow: 0 0 2px #cecece;
  }
}

.page-wrapper {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
}
.page-wrapper {
  overflow: hidden;
}

.cover {
  position: relative;
  height: 50rem;
  margin-bottom: -9.6875rem;
  overflow: hidden;
  img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    transform: none;
    object-fit: cover;
    object-position: center center;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top,rgba(0,0,0,0.8) 0%,transparent 100%);
    z-index: 1;
  }
}

.main-field {
  position: relative;
  z-index: 10;
  margin-bottom: 70px;
}

.slogan {
  font-size: 80px;
  line-height: 1.3;
  font-family: "NestleBrush", sans-serif;
  margin: 0 auto 1.5625rem;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

.filter-wrap {
  padding: 0 1.25rem;
  margin: 0 auto 1.25rem;
  max-width: 96.875rem;
}

.filter-box {
  position: relative;
  background-color: #A50064;
  border-radius: 15px;
  padding: 1.875rem 3.125rem;
  h3 {
    text-align: center;
    color: #ffffff;
    font-family: "Nestle-Text-Bold", sans-serif;
    font-size: 26px;
    margin-bottom: 2.1875rem;
  }
}

.filter-wrap {
  text-align: center;
  .btn {
    margin-top: 0.9375rem;
    .icon {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      background: url("../img/search-icon-blue.svg") no-repeat;
      background-size: 100%;
      top: 0;
      vertical-align: text-bottom;
      margin-left: 0;
      margin-right: 0.625rem;
    }
  }
}

.filter {
  display: flex;
  .form-item {
    width: 25%;
    padding-left: 1rem;
    margin-bottom: 1rem;
    &:first-child {
      padding-left: 0;
    }
    input, select {
      display: block;
      appearance: none;
      border: 1px solid #d1cbc5;
      font-family: "Nestle-Text-Book", sans-serif;
      font-size: 13px;
      line-height: 20px;
      border-radius: 2px;
      padding: 7px 11px 7px;
      vertical-align: middle;
      text-decoration: none;
      width: 100%;
      height: 36px;
      color: #30261D;
      box-shadow: 0 0 0 #a1978b;
      background: #ffffff;
      transition: border-color 0.1s ease,box-shadow 0.1s ease;
      box-sizing: border-box;
      outline: none;
    }
    select {
      position: relative;
      background: #ffffff url("../img/dropdown_arrow.svg") no-repeat center right 10.5px;
      padding-right: 35px;
    }
  }
  label {
    text-align: left;
    display: block;
    color: #ffffff;
    margin-bottom: 0.25rem;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  .input-group {
    position: relative;
    svg {
      width: 16px;
      position: absolute;
      right: 10px;
      top: 3px;
      path {
        fill: #848584;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .filter {
    flex-wrap: wrap;
    .form-item {
      width: 50%;
      &:nth-child(3) {
        padding-left: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .slogan {
    font-size: 36px;
    padding: 0;
  }
  .cover {
    height: 23.75rem;
    margin-bottom: -4.375rem;
  }
  .filter-box {
    h3 {
      font-size: 16px;
      margin-bottom: 1.375rem;
    }
  }
  .view-search {
    h2 {
      font-size: 18px;
    }
  }
}

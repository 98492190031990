.ReactModal__Overlay {
    z-index: 1000;
}

.ReactModal__Content {
    max-width: 1000px;
    width: 100%;
    .modal-close {
        position: absolute;
        top: 15px;
        right: 10px;
        background: none;
        border: none;
        cursor: pointer;
        color: #ccc;
        transition: all .3s;
        img {
            width: 30px;
        }
        &:focus {
            outline: none;
        }
    }
}

.vacancy-content-modal {
    padding: 0 20px 30px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    &-info-block {
        margin: 20px 0;
        padding: 20px;
        background: rgba(32, 150, 243, 0.15);
        display: flex;
        & > div {
            width: 33.3%;
            padding-right: 25px;
        }
        &-title {
            color: #888;
            font-size: 70%;
            padding-bottom: 1em;
        }
    }
    .apply-btn {
        color: white;
        background: #3a70bb;
        padding: 10px;
        border: none;
        font-size: inherit;
        cursor: pointer;
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
        margin-top: 25px;
        &:hover {
            background: #4487dd;
        }
    }
    .b-vacancy-respond-message {
        margin: 20px 0;
        padding: 20px;
        background: rgba(32, 150, 243, 0.15);
    }
}

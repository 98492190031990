@font-face {
  font-family: 'Nestle-Text-Book';
  src: url("../fonts/nestle_text-book.eot");
  src: url("../fonts/nestle_text-book.woff") format("woff"), url("../fonts/nestle_text-book.woff") format("woff"), url("../fonts/nestle_text-book.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nestle-Text-Light';
  src: url("../fonts/nestle_text-light.eot");
  src: url("../fonts/nestle_text-light.woff") format("woff"), url("../fonts/nestle_text-light.woff") format("woff"), url("../fonts/nestle_text-light.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nestle-Text-Book';
  src: url("../fonts/nestle_text-light.eot");
  src: url("../fonts/nestle_text-light.woff") format("woff"), url("../fonts/nestle_text-light.woff") format("woff"), url("../fonts/nestle_text-light.eot?#iefix") format("embedded-opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nestle-Text-Bold';
  src: url("../fonts/nestle_text-bold.eot");
  src: url("../fonts/nestle_text-bold.woff") format("woff"), url("../fonts/nestle_text-bold.woff") format("woff"), url("../fonts/nestletext-bold.ttf") format("truetype"), url("../fonts/nestle_text-bold.eot?#iefix") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?6mzl05");
  src: url("../fonts/icomoon.eot?6mzl05#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?6mzl05") format("truetype"), url("../fonts/icomoon.woff?6mzl05") format("woff");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'NestleScript';
  src: url("../fonts/nestlescript-regular.eot");
  src: url("../fonts/nestlescript-regular.woff") format("woff"), url("../fonts/nestlescript-regular.woff") format("woff"), url("../fonts/nestlescript-regular.ttf") format("truetype"), url("../fonts/nestlescript-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NestleBrush';
  src: url("../fonts/nestlebrush-regular.eot");
  src: url("../fonts/nestlebrush-regular.woff") format("woff"), url("../fonts/nestlebrush-regular.woff") format("woff"), url("../fonts/nestlebrush-regular.ttf") format("truetype"), url("../fonts/nestlebrush-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

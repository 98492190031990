.list {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  text-align: left;
  border-spacing: 0;
  border-style: none;
  thead {
    tr {
      font-weight: 700;
      vertical-align: middle;
      font-size: 1em;
      color: #ffffff;
      &:first-child>tr:first-child>th {
        border-top: 0;
      }
    }
    th {
      white-space: nowrap;
      background-color: #6C707A;
      color: #ffffff;
      border-bottom: 1px solid #ffffff;
    }
  }
  th, td {
    border: 0;
    padding: 10px;
  }
  td {
    white-space: normal;
    border-bottom: 1px solid #ffffff;
    transition: all .3s;
    &.title span {
      color: #007CBA;
      cursor: pointer;
      &:hover {
        color: darken($color: #007CBA, $amount: 10%)
      }
    }
  }
  .title {
    width: 50%;
  }
  .company, .function {
    width: 25%;
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: #e8e8e9;
        border: 0;
        border-top: 1px solid #ffffff;
        vertical-align: middle;
      }
      &:nth-child(even) {
        border-color: #ffffff;
        background-color: #F4F4F5;
        border-left: 0;
        border-right: 0;
      }
    }
  }
}

.view-search {
  font-family: "Nestle-Text-Light", sans-serif;
}

@media (max-width: 767px) {
  .list {
    thead {
      display: none;
    }
    tr {
      display: block;
      margin-bottom: 20px;
    }
    td {
      display: flex;
      border: 0;
    }
  }
  .view-search {
    .list {
      td {
        width: 100%;
      }
    }
  }
}

.vacancies-table {
  position: relative;
  .vacancies-table-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #fff, $alpha: .3);
    display: flex;
    align-items: center;
  }
}

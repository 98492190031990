.header {
  position: relative;
}
.row-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0 32px;
  .logo {
    max-width: 363px;
    margin: 0;
    position: relative;
    z-index: 1;
    line-height: 0;
    outline: none;
    img {
      max-width: 363px;
    }
  }
}
.menu-wrap {
  background: #F6F5F4;
  position: relative;
  z-index: 99;
}
.nav-wrap {
  display: flex;
  justify-content: space-between;
  .nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      a {
        display: block;
        color: #30261D;
        transition: 0.3s ease;
        font-size: 16px;
        padding: 0 10px;
        line-height: 48px;
        &:hover {
          background: #d1cbc5;
          color: #30261D;
        }
      }
    }
    .home a{
      font-size: 0;
      width: 20px;
      height: 20px;
      background: url("../img/sprite-image.png") no-repeat;
      background-position: -88px 0;
      background-size: 207px;
      margin-right: 16px;
    }
    &:last-child {
      font-weight: bold;
    }
  }
}
.hamburger-menu {
  position: relative;
  display: none;
  width: 34px;
  height: 34px;
  padding: 1.5px 0;
  margin-top: 44px;
  border: 3px solid transparent;
  float: right;
  cursor: pointer;
  outline: none;
  transition: border-color 0.16667s ease,border-radius 0.166667s ease;
  &:after,&:before {
    content: '';
    width: 100%;
    transform-origin: center center;
  }
  &:before, span {
    margin-bottom: 8px;
  }
  span {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  &:after,&:before, span {
    display: block;
    height: 3px;
    background: #30261D;
    transition: opacity 0.16667s ease,transform 0.166667s ease,width 0.16667s ease,background 0.16667s ease;
  }
}
@media (max-width: 1023px) {
  .hamburger-menu {
    display: inline-block;
    position: absolute;
    margin: 0;
    background: transparent;
    right: 5%;
    top: -70px;
    outline: none;
  }
  .nav-wrap {
    display: none;
  }
  .header .container {
    width: 90%;
  }
  .open-menu {
    .nav-wrap {
      display: block;
      position: absolute;
      background: #fff;
      left: 0;
      right: 0;
      padding: 10px 33% 30px 5%;
      border-top: 2px solid #e9e5e3;
      a {
        padding: 12px 35px 12px 8px;
      }
      li {
        border-bottom: 1px solid #d1cbc5;
        width: 100%;
      }
      .home {
        display: none;
      }
      .nav {
        margin-top: 32px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .hamburger-menu {
      border-radius: 50%;
      border-color: #007CBA;
      padding-top: 1.5px;
      &:before {
        transform: translateY(11px) rotate(45deg) scaleX(0.7);
        background: #007CBA;
      }
      span {
        opacity: 0;
      }
      &:after {
        transform: translateY(-11px) rotate(-45deg) scaleX(0.7);
        background: #007CBA;
      }
    }
  }
}
@media (max-width: 767px) {
  .open-menu {
    .nav-wrap{
      padding: 10px 5% 30px;
    }
  }
  .filter-wrap {
    .filter .form-item {
      width: 100%;
      padding-left: 0;
    }
    .btn {
      display: block;
      width: 100%;
    }
    .filter-box {
      padding: 2.1875rem 1.25rem;
    }
  }
  .main-field {
    .filter-wrap {
      padding: 0;
    }
  }
  .row-header {
    .logo {
      max-width: 200px;
      img {
        width: 100%;
      }
    }
  }
  .header {
    &:before,&:after {
      content: '';
      display: table;
      clear: both;
    }
  }
  .open-search {
    .search {
      bottom: inherit;
      top: 84px;
    }
  }
}

.footer-wrapper {
  margin-top: 4rem;
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .col-md-3 {
    padding-top: 0;
  }
}

.footer-mini {
  padding: 24px 0 16px 0;
  background-color: #007CBA;
  color: #ffffff;
  h3 {
    margin-bottom: 21px;
    a {
      color: #ffffff;
      font-family: "Nestle-Text-Light", sans-serif;
      font-weight: 100;
      line-height: 29px;
      font-size: 22px;
      text-decoration: none;
    }
  }
  .btn {
    margin-bottom: 2em;
  }
  img {
    opacity: 0.5;
  }
  .col-md-3 {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.footer-top {
  background-color: #f2f2f2;
  padding: 38px 0 0 0;
  h2 {
    font-size: 26px;
    text-transform: none;
    line-height: 1.2;
  }
  p {
    margin-top: 1.5rem;
    margin-bottom: 0;
    width: 75%;
    font-size: 24px;
    color: #30261D;
    padding-bottom: 26px;
  }
  .footer-logo {
    width: 100%;
    max-width: 157px;
    margin-bottom: 2rem;
  }
  .col-nav {
    h2 {
      color: #63513d;
      text-transform: uppercase;
      font-size: 16px;
      font-family: "Nestle-Text-Light", sans-serif;
      line-height: 2.03;
      margin: 7px 0 8px 0;
      border-top: 1px solid #8A7969;
      padding-top: 5px;
      width: 85%;
    }
    .menu {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      li {
        position: relative;
        display: block;
        line-height: 20.16px;
        padding-bottom: 14px;
        a {
          position: relative;
          display: block;
          font-size: 0.875rem;
          font-weight: 400;
          padding: 0;
          border-bottom: none;
          line-height: 1.49;
          color: #30261D;
          transition: transform 0.1s ease,color 0.1s ease;
          &:hover {
            color: #007CBA;
            border-bottom: none;
            background-color: transparent;
            transform: translateX(3px);
          }
        }
      }
    }
  }
}

.footer-bottom {
  background-color: #f2f2f2;
  h2 {
    color: #63513d;
    font-size: 26px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 19px;
    display: block;
    text-align: center;
    font-family: "NestleBrush", sans-serif;
  }
  .social-links-wrapper {
    justify-content: center;
    flex-wrap: nowrap;
    position: relative;
    padding-bottom: 53px;
    text-align: center;
    &:hover .social-icon {
      background-color: #BFC0C5;
      &:hover {
        background-color: #007CBA;
      }
    }
    .social-platform-wrapper {
      display: inline-block;
      position: relative;
      margin: 0 10px;
      cursor: default;
      a {
        display: block;
        height: 44px;
        width: 44px;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
        border-radius: 50%;
        background-color: #007CBA;
        color: #fff;
        overflow: hidden;
        transition: background-color 0.1s ease;
        svg {
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          fill: #fff;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          font-size: 24px;
        }
        &.icon-fb {
          svg {
            width: 0.5em;
            height: 0.875em;
          }
        }
        &.icon-vk {
          svg {
            width: 1em;
            height: 0.563em;
          }
        }
        &.icon-tw {
          svg {
            width: 1em;
            height: 0.813em;
          }
        }
        &.icon-fli {
          svg {
            width: 1.063em;
            height: 0.438em;
          }
        }
      }
    }
  }
}

.footer-below {
  background: #ffffff;
  padding: 12px 0 13px 0;
  .sub-footer-menu {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin: 0;
    list-style: none;
    li {
      display: inherit;
      a {
        color: #63513d;
        display: block;
        padding: 0 19px 0 0;
        font-size: 13px;
        line-height: 17px;
        &:after {
          content: "|";
          padding-left: 19px;
          color: #d0cbc5;
        }
      }
    }
  }
}

a:hover img {
  opacity: 0.9;
}

@media screen and (max-width: 1199px) {
  .footer-wrapper {
    margin-top: 0;
  }
  .footer-top {
    .row {
      flex-wrap: wrap;
      .col-md-3 {
        width: 50%;
      }
    }
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .footer-mini {
    .row {
      flex-wrap: wrap;
      .col-md-3 {
        width: 50%;
        img {
          max-width: 218px;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .footer-below .sub-footer-menu li a {
    padding: 6px 0;
    &:after {
      display: none;
    }
  }
  .footer-below ul li {
    width: 46%;
    margin-right: 16px;
    text-align: center;
    border-top: 1px solid #d0cbc5;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .footer-mini {
    .row {
      flex-wrap: wrap;
    }
    .col-md-3 {
      width: 100%;
      padding-left: 0;
    }
  }
  .footer-top {
    p {
      font-size: 20px;
      width: 100%;
    }
    .row .col-md-3 {
      width: 100%;
    }
    .footer-logo {
      max-width: 92px;
      margin-bottom: 0;
    }
  }
  .footer-below {
    padding: 25px 0 13px;
    ul li {
      width: 100%;
    }
  }
}


.btn {
  display: inline-block;
  border: 1px solid;
  text-align: center;
  font-family: "Nestle-Text-Book", sans-serif;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
  border-radius: 2px;
  padding: 7px 1.28571em 7px;
  vertical-align: middle;
  text-decoration: none;
  transition: background-color 0.1s ease,border-color 0.1s ease,box-shadow 0.1s ease;
  cursor: pointer;
}
.btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #007CBA;
  &:hover {
    color: #007CBA;
    background-color: #cce5f1;
    border-color: #cce5f1;
  }
}
.btn-transparent {
  background: rgba(0,0,0,0);
  border-color: #ffffff;
  color: #ffffff;
  box-shadow: 0 1px 2px rgba(0,0,0,0.5);
  &:hover {
    color: #ffffff;
    background-color: rgba(0,0,0,0.15);
  }
}
.search {
  display: inline-flex;
  position: relative;
  input {
    display: inline-block;
    font-size: 16px;
    padding: 11px 33px 11px 9px;
    color: #30261D;
    height: auto;
    border-radius: 0;
    font-family: "Nestle-Text-Light", sans-serif;
    line-height: 21px;
    width: 320px;
    transition: 0.3s;
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    border-bottom: 1px solid #63513d;
    &:focus {
      width: 380px;
    }
  }
}
@media screen and (min-width: 1280px) {
  .search input:focus {
    width: 500px;
  }
}
.btn-search {
  margin-top: 0;
  width: 27px;
  height: 44px;
  border: 0;
  background: url("../img/search-icon.svg") no-repeat center;
  background-color: #ffffff;
  font-size: 0;
  border-bottom: none;
  box-shadow: none;
  border-radius: 0;
  outline: none;
  color: #ffffff;
  border-bottom: 1px solid #63513d;
  top: 12px;
  text-align: center;
  text-transform: capitalize;
  padding: 0 0.7em;
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
}
.btn-search-clear {
  position: absolute;
  bottom: 6px;
  right: 40px;
  border: none;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  img {
    width: 20px;
  }
}

.btn-search-mobile {
  display: none;
  border: 0;
  width: 34px;
  height: 32px;
}

@media screen and (max-width: 1024px) {
  .btn-search-mobile {
    display: inline-block;
    margin-right: 50px;
  }
  .search {
    display: none;
    input {
      display: inline-flex;
      width: inherit;
      flex: 1;
    }
  }
  .open-search {
    .search {
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      background: #fff;
      bottom: -100%;
      z-index: 100;
      padding: 20px 5%;
    }
    .btn-search-mobile {
      display: block;
      position: relative;
      top: -5px;
      width: 34px;
      height: 32px;
      border: 0;
      background: url('../img/button-close.svg') no-repeat;
    }
  }
}

html, body {
  font-family: "Nestle-Text-Book", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #63513D;
}

h2 {
  font-family: "Nestle-Text-Book", sans-serif;
  font-weight: 500;
  padding: 0;
  color: #63513d;
}

h3 {
  font-size: 1.3em;
}

a {
  color: #337ab7;
  text-decoration: none;
}

@media (min-width: 1280px) {
  h3 {
    font-size: 1.6em;
  }
}
